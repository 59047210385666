<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Keywords <span
          v-if="!isAdmin"
          class="text-danger"
        >*</span></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <p
          v-if="!isAdmin"
          class="alert alert-warning custom-alert"
        >
          Add at least 1 keywords
        </p>
        <div class="row">
          <div class="col">
            <div class="mb-1"><!-- añadid un modal de confirmación para borrar un keyword -->
              <span
                v-for="(keyw, index) in user.keywords"
                :key="index"
                class="badge badge-light-dark mx-50 mb-1"
              >
                {{ keyw.word.replace(/<[^>]*>/g, '') }}
                <a
                  class="ms-1 text-black"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete filter"
                  @click="deleteKeyWord(index)"
                ><i data-feather="x" /></a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a
          class="btn btn-sm btn-dark dis-ib"
          data-bs-toggle="collapse"
          role="button"
          aria-expanded="false"
          aria-controls="collapseAddFile2"
          @click="openEdit = !openEdit"
        ><i data-feather="plus" /> Add keyword</a>

        <b-collapse
          :visible="openEdit"
          class="collapse mt-3"
        >
          <div class="card shadow-none bg-transparent border-secondary">
            <div class="card-body">
              <h3>Add keywords</h3>
              <p>{{ labels['add_keyword.description'] }}</p>
              <div class="row">
                <div class="col">
                  <div class="mb-1">
                    <label
                      for=""
                      class="form-label"
                    >{{ labels['add_keyword.content'] }}</label>
                    <input
                      v-model="newKeyWords"
                      type="text"
                      class="form-control"
                      placeholder="Ex.: aerodynamics, engine engineering, f1"
                    >
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <a
                  class="btn btn-sm btn-outline-success me-1"
                  @click="saveNewKeyWords"
                >Save keywords</a>
                <a
                  class="btn btn-sm btn-flat-danger"
                  data-bs-toggle="collapse"
                  href="#collapseAddFile2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseAddFile2"
                >Cancel</a>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
    selectedRoles: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      openEdit: false,
      newKeyWords: '',
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'auth/admin',
    }),
    isAdmin() {
      return this.loggedUser.roles.includes('super-admin')
    },
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.keywords`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveNewKeyWords() {
      if (this.newKeyWords.length > 0) {
        const splitted = this.newKeyWords.split(', ')
        splitted.forEach(element => {
          this.user.keywords.push({ word: element })
        })

        this.newKeyWords = ''
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 500)
      }
    },
    async deleteKeyWord(index) {
      await this.user.keywords.splice(index, 1)
      this.$toastr.success('Remember to save to apply the changes', 'Keyword deleted successfully!')

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>

<style>

</style>
